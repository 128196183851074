import React, { useState, useEffect, useContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveDailyQuestionResponse, getDailyQuestionResponses } from '../../../../../services/dailyQuestionService';
import './DailySurvey.css';
import { fetchQ20Data } from '../../../../../services/surveyService';
import { getScheduledNotificationForObservationPeriod } from '../../../../../services/notificationsService';
import { addHourlyNotificationsFromDailySurvey } from '../../../../../services/notificationsService';
import NotificationContext from '../../../../Notification/NotificationContext';
import DailySurveyChart from '../../../Share/DailySurveyChart/DailySurveyChart';
import { useTranslation } from 'react-i18next';

const DailySurveyComponent = ({ userId }) => {
    const [wakeUpTime, setWakeUpTime] = useState('');
    const [bedTime, setBedTime] = useState('');
    const [sleepQuality, setSleepQuality] = useState(5);
    const [alreadyAnswered, setAlreadyAnswered] = useState(false);
    const [savedResponse, setSavedResponse] = useState(null);
    const [responses, setResponses] = useState([]);
    const [activeTab, setActiveTab] = useState('Survey');
    const [currentPage, setCurrentPage] = useState(1);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [proposedNotifications, setProposedNotifications] = useState([]);
    const recordsPerPage = 2;

    const { markDailySurveyAsDone } = useContext(NotificationContext);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await getDailyQuestionResponses(userId);
                const fetchedResponses = response.data;
                setResponses(fetchedResponses);

                if (fetchedResponses && fetchedResponses.length > 0) {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    const tomorrow = new Date(today);
                    tomorrow.setDate(today.getDate() + 1);

                    const todayResponse = fetchedResponses.find(response => {
                        const responseDate = new Date(response.date);
                        return responseDate >= today && responseDate < tomorrow;
                    });

                    if (todayResponse) {
                        setAlreadyAnswered(true);
                        setSavedResponse(todayResponse);
                        setWakeUpTime(todayResponse.wakeUpTime);
                        setBedTime(todayResponse.bedTime);
                        setSleepQuality(todayResponse.sleepQuality);
                    }
                }
            } catch (error) {
                console.error('Error fetching daily question responses:', error);
            }
        };
        fetchResponses();
    }, [userId]);

    const handleSaveDailyQuestionResponse = async () => {
        try {
            const now = new Date();
            const q20Data = await fetchQ20Data(userId);
            const { startTime } = q20Data;
            const [startHour] = startTime.split(':').map(Number);
    
            const scheduledData = await getScheduledNotificationForObservationPeriod(userId);
            const notifications = scheduledData.dateRanges.flatMap(range => range.notifications);
    
            const currentHour = now.getHours();
            const newNotifications = [];
    
            // Check every hour from the current hour up to (but not including) the start time
            for (let hour = currentHour; hour < startHour; hour++) {
                const notificationTime = new Date(now);
                notificationTime.setHours(hour, 0, 0, 0);
    
                const isNotificationScheduled = notifications.some(notif =>
                    new Date(notif.notificationTime).getHours() === hour &&
                    new Date(notif.notificationTime).getDate() === notificationTime.getDate()
                );
    
                if (!isNotificationScheduled) {
                    newNotifications.push(notificationTime);
                }
            }
    
            // If there are unscheduled notifications, propose them to the user
            if (newNotifications.length > 0) {
                setProposedNotifications(newNotifications);
                setDialogVisible(true);
            } else {
                // If all hours are already scheduled, save the response
                await saveResponse();
            }
        } catch (error) {
            console.error('Error checking or scheduling notifications:', error);
            toast.error(t('dailySurvey.surveySaveError'));
        }
    };
    
    const saveResponse = async () => {
        try {
            await saveDailyQuestionResponse(userId, { wakeUpTime, bedTime, sleepQuality });
            toast.success(t('dailySurvey.surveyUpdated'));
            setAlreadyAnswered(true);
            setSavedResponse({ wakeUpTime, bedTime, sleepQuality });
            markDailySurveyAsDone();
        } catch (error) {
            console.error('Error saving daily question response:', error);
            toast.error(t('dailySurvey.surveySaveError'));
        }
    };

    const handleDialogYes = async () => {
        try {
            // Convert proposedNotifications to ISO strings for the API call
            const notificationTimes = proposedNotifications.map(time => time.toISOString());
    
            // Call the new service method to schedule notifications
            await addHourlyNotificationsFromDailySurvey(userId, notificationTimes);
    
       //     toast.success(t('dailySurvey.notificationsScheduled'));
    
            // Save the survey response after scheduling notifications
            await saveResponse();
        } catch (error) {
            console.error('Error scheduling notifications:', error);
            toast.error(t('dailySurvey.notificationError'));
        } finally {
            // Close the dialog regardless of success or failure
            setDialogVisible(false);
        }
    };
    

    const handleDialogNo = async () => {
        setDialogVisible(false);
        await saveResponse();
    };

    const renderDialog = () => (
        <div className="dialog">
            <p>
                {t('dailySurvey.earlyWakeupMessage')}
                {proposedNotifications.map((time, index) => (
                    <span key={index}>{` ${time.toLocaleTimeString()} `}</span>
                ))}
            </p>
            <button onClick={handleDialogYes}>{t('dailySurvey.yes')}</button> &nbsp;
            <button onClick={handleDialogNo}>{t('dailySurvey.no')}</button>
        </div>
    );

    const renderSurvey = () => (
        <div>
            {alreadyAnswered && savedResponse ? (
                <div>
                    <p>{t('dailySurvey.alreadyAnswered')}</p>
                    <p><strong>{t('dailySurvey.wakeUpTime')}</strong> {savedResponse.wakeUpTime}</p>
                    <p><strong>{t('dailySurvey.bedTime')}</strong> {savedResponse.bedTime}</p>
                    <p><strong>{t('dailySurvey.sleepQuality')}</strong> {savedResponse.sleepQuality}</p>
                    <button onClick={() => setAlreadyAnswered(false)} className="edit-button">{t('dailySurvey.editResponse')}</button>
                </div>
            ) : (
                <div>
                    <div>
                        <label className="label">{t('dailySurvey.whenDidYouWakeUp')}</label>
                        <input type="time" className="input" value={wakeUpTime} onChange={(e) => setWakeUpTime(e.target.value)} />
                    </div>
                    <div>
                        <label className="label">{t('dailySurvey.whenDidYouGoToBed')}</label>
                        <input type="time" className="input" value={bedTime} onChange={(e) => setBedTime(e.target.value)} />
                    </div>
                    <div>
                        <label className="label">{t('dailySurvey.rateYourSleep')}</label>
                        <input
                            type="range"
                            min="1"
                            max="10"
                            value={sleepQuality}
                            onChange={(e) => setSleepQuality(e.target.value)}
                            className="slider"
                        />
                        <p>{t('dailySurvey.sleepQuality')} {sleepQuality}</p>
                    </div>
                    {dialogVisible ? (
                        <div className="dialog">
                            <p>{t('dailySurvey.earlyWakeupMessage')}</p>
                            {proposedNotifications.map((time, index) => (
                                <p key={index}>{time.toLocaleTimeString()}</p>
                            ))} 
                            <button onClick={handleDialogYes} className="yes-button">{t('dailySurvey.yes')}</button> &nbsp;
                            <button onClick={handleDialogNo} className="no-button">{t('dailySurvey.no')}</button>
                        </div>
                    ) : (
                        <button onClick={handleSaveDailyQuestionResponse} className="save-button">
                            {t('dailySurvey.save')}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
    

    const renderHistory = () => {
        const groupedResponses = responses.reduce((acc, response) => {
            const date = new Date(response.date).toLocaleDateString();
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(response);
            return acc;
        }, {});

        const totalPages = Math.ceil(Object.keys(groupedResponses).length / recordsPerPage);

        const paginatedResponses = Object.entries(groupedResponses)
            .slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);

        return (
            <div>
                <h4>{t('dailySurvey.previousResponses')}</h4>
                {paginatedResponses.length > 0 ? (
                    paginatedResponses.map(([date, responsesForDay], index) => (
                        <div key={index} className={`response-group day-group-${index}`}>
                            <h5 className="response-date">{date}</h5>
                            {responsesForDay.map((response, i) => (
                                <div key={i} className="response-item">
                                    <p><strong>{t('dailySurvey.wakeUpTime')}</strong> {response.wakeUpTime}</p>
                                    <p><strong>{t('dailySurvey.bedTime')}</strong> {response.bedTime}</p>
                                    <p><strong>{t('dailySurvey.sleepQuality')}</strong> {response.sleepQuality}</p>
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <p>{t('dailySurvey.noPreviousResponses')}</p>
                )}
                <div className="pagination-controls">
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        {t('dailySurvey.back')}
                    </button>
                    <span>{t('dailySurvey.page', { currentPage, totalPages })}</span>
                    <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        {t('dailySurvey.next')}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="container">
            <ToastContainer />
            <h3>{t('dailySurvey.surveyTitle')}</h3>
            <div className="tabs">
                <button
                    onClick={() => setActiveTab('Survey')}
                    className={`tab-button ${activeTab === 'Survey' ? 'active-tab' : ''}`}
                >
                    {t('dailySurvey.surveyTab')}
                </button>
                <button
                    onClick={() => setActiveTab('History')}
                    className={`tab-button ${activeTab === 'History' ? 'active-tab' : ''}`}
                >
                    {t('dailySurvey.historyTab')}
                </button>
                <button
                    onClick={() => setActiveTab('Chart')}
                    className={`tab-button ${activeTab === 'Chart' ? 'active-tab' : ''}`}
                >
                    {t('dailySurvey.chartTab')}
                </button>
            </div>

            <div className="tab-content">
                {activeTab === 'Survey' && renderSurvey()}
                {activeTab === 'History' && renderHistory()}
                {activeTab === 'Chart' && <DailySurveyChart responses={responses} />}
            </div>
        </div>
    );
};

export default DailySurveyComponent;