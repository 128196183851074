import axios from 'axios';
import { handleSessionExpired } from '../components/SessionExpiredHandler';

const API_URL = process.env.REACT_APP_API_URL;

export const checkTokenValidity = async () => {
  try {
    const response = await axios.get(`${API_URL}/auth/check-token`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : ''}`
      }
    });
    return response.status === 200;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleSessionExpired();
    }
    return false;
  }
};

export const register = async (userData) => {
  return await axios.post(`${API_URL}/auth/register`, userData);
};

export const login = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, userData);
    if (response.data.token) {
      localStorage.setItem('user', JSON.stringify({
        token: response.data.token,
        isAdmin: response.data.isAdmin,
        userId: response.data.userId  // Store userId in local storage
      }));
    }
    return response.data;
  } catch (error) {
    console.error('Error during login:', error);
    throw error; // Rethrow to handle it in the login component
  }
};

export const logout = () => {
  localStorage.removeItem('user');
};

export const getToken = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user ? user.token : null;
};

export const getUserId = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user ? user.userId : null;
};

export const sendVerificationCode = async (userData) => {
  return await axios.post(`${API_URL}/auth/send-verification-code`, userData);
};

export const verifyEmailAndRegister = async (email, verificationCode, username, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/verify-email-and-register`, {
      email,
      verificationCode,
      username,  // Include username
      password,  // Include password
    });

    if (response.data.token) {
      localStorage.setItem('user', JSON.stringify({
        token: response.data.token,
        userId: response.data.userId,
      }));
    }
    return response.data;
  } catch (error) {
    console.error('Error verifying email and registering:', error);
    throw error;
  }
};


export const sendPasswordResetEmail = async (email) => {
  return await axios.post(`${API_URL}/auth/send-password-reset-email`, { email });
};


export const resetPassword = async (token, password) => {
  return await axios.post(`${API_URL}/auth/reset-password`, { token, password });
};