import React, { useState, useContext } from 'react';
import { Card, TextField, Button, Tabs, Tab, IconButton, InputAdornment, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login as loginUser, sendVerificationCode, verifyEmailAndRegister } from '../../services/authService';
import { UserContext } from '../../contexts/UserContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './Login.css';
import { getUserProfile } from '../../services/userService';

const LoginApp = ({ setLoggedIn }) => {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerUsername, setRegisterUsername] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const { login } = useContext(UserContext);

  const handleInputChange = (event, setState) => {
    setState(event.target.value);
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    const userData = { login: loginEmail, password: loginPassword };
    try {
      const response = await loginUser(userData);
      const { token, isAdmin, userId } = response;

      const userProfile = await getUserProfile(token);
      const { firstname, email, username, avatar, avatarContentType } = userProfile;
      const user = { firstname, email, username, avatar, avatarContentType, token, isAdmin, userId };

      localStorage.setItem('user', JSON.stringify(user));
      login(user);

      if (user.isAdmin) {
        navigate('/admin-dashboard');
      } else {
        navigate('/dashboard');
      }
      setLoggedIn(true);
    } catch (error) {
      console.error('Login failed', error.response ? error.response.data : "No response");
      toast.error('Wrong email or password');
    }
  };

  const handleSendVerificationCode = async () => {
    if (!registerEmail) {
      toast.error('You must provide an email address to send the verification code.');
      return;
    }
    if (!registerPassword || !/^(?=.*[A-Z]).{6,}$/.test(registerPassword)) {
      toast.error('Password must be at least 6 characters long and include at least one uppercase letter.');
      return;
    }
    if (registerPassword !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    const userData = { email: registerEmail, username: registerUsername, password: registerPassword };
    console.log(userData)
    try {
      await sendVerificationCode(userData);
      toast.success('Verification code sent to your email. Please enter the code to complete registration.');
      setIsVerificationSent(true);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error('Email already exists. Please try logging in first.');
      } else {
        console.error('Error sending verification code', error);
        toast.error('Failed to send verification code.');
      }
    }
  };


  const handleVerificationSubmit = async (event) => {
    event.preventDefault();
    console.log({
      email: registerEmail,
      verificationCode,
      username: registerUsername,
      password: registerPassword,
  }); // Log the data to confirm

    try {
      const data = await verifyEmailAndRegister(registerEmail, verificationCode, registerUsername, registerPassword);
      toast.success('Email verified and registration complete!');

      const user = { token: data.token, userId: data.userId };
      localStorage.setItem('user', JSON.stringify(user));
      login(user);

      navigate('/dashboard');
      setLoggedIn(true);
    } catch (error) {
      if (error.response && error.response.status === 409 && error.response.data.message.includes('Username')) {
        toast.error('Username already exists. Please choose a different one.');
      } else {
        console.error('Verification failed', error);
        toast.error('Verification failed. Please check the code and try again.');
      }
    }
  };


  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <ToastContainer />
      <Card className={`login-card ${tabIndex === 0 ? 'login' : 'register'}`}>
        <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" className="login-tabs">
          <Tab label="Login" className="login-tab" />
          <Tab label="Signup" className="login-tab" />
        </Tabs>
        {tabIndex === 0 && (
          <form className="login-form login" onSubmit={handleLoginSubmit}>
            <TextField
              label="Your Email"
              type="email"
              value={loginEmail}
              onChange={(e) => handleInputChange(e, setLoginEmail)}
              fullWidth
              margin="normal"
              variant="outlined"
              className="login-input"
            />
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={loginPassword}
              onChange={(e) => handleInputChange(e, setLoginPassword)}
              fullWidth
              margin="normal"
              variant="outlined"
              className="login-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button type="submit" variant="contained" className="login-button">
              Log In
            </Button> <br></br><br></br>
            <Typography variant="body2" align="center" className="forgot-password">
              <Link to="/forgot-password" className="forgot-password-link">
                Forgot your password? Click here!
              </Link>
            </Typography>
          </form>

        )}
        {tabIndex === 1 && (
          <form className="login-form register">
            <TextField
              label="Your Email"
              type="email"
              value={registerEmail}
              onChange={(e) => handleInputChange(e, setRegisterEmail)}
              fullWidth
              margin="normal"
              variant="outlined"
              className="login-input"
            />
            <TextField
              label="Username"
              value={registerUsername}
              onChange={(e) => handleInputChange(e, setRegisterUsername)}
              fullWidth
              margin="normal"
              variant="outlined"
              className="login-input"
            />
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={registerPassword}
              onChange={(e) => handleInputChange(e, setRegisterPassword)}
              fullWidth
              margin="normal"
              variant="outlined"
              className="login-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => handleInputChange(e, setConfirmPassword)}
              fullWidth
              margin="normal"
              variant="outlined"
              className="login-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Verification Code"
                type="text"
                value={verificationCode}
                onChange={(e) => handleInputChange(e, setVerificationCode)}
                variant="outlined"
                className="login-input"
                style={{ flex: 2, marginRight: '145px' }} // Make the input field larger
              />
              <Button
                variant="contained"
                onClick={handleSendVerificationCode}
                style={{ minWidth: '80px', padding: '6px 8px' }}
              >
                Send Code
              </Button>
            </div>
            <Button
              variant="contained"
              onClick={handleVerificationSubmit}
              className="login-button"
              style={{ marginTop: '20px' }}
            >
              Register
            </Button>
          </form>
        )}

      </Card>
    </>
  );
};

export default LoginApp;
