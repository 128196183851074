import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import ApexCharts from 'react-apexcharts';
import { getPerformanceResponses } from '../../../../services/performanceService';
import { getScheduledNotificationForObservationPeriod } from '../../../../services/notificationsService';
import { fetchQ20Data } from '../../../../services/surveyService';
import { format } from 'date-fns';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
    Tooltip,
    Paper
} from '@mui/material';
import {
    ArrowBack,
    CompareArrows,
    HourglassBottom as HourlyIcon,
    CalendarToday as WeeklyIcon
} from '@mui/icons-material';

const SurveyProgressBar = ({ userId, onReturn }) => {
    const [scheduledNotifications, setScheduledNotifications] = useState([]);
    const [performanceData, setPerformanceData] = useState([]);
    const [q20Data, setQ20Data] = useState({ startTime: '', duration: 0 });
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [chartView, setChartView] = useState('hourly'); // 'hourly' or 'weekly'
    const [chartRef, setChartRef] = useState(null);
    const [apexChartOptions, setApexChartOptions] = useState({});
    const [apexChartSeries, setApexChartSeries] = useState([]);

    // Chart generation utilities
    const generateHourlyChart = (canvasRef) => {
        // Validate inputs
        if (!canvasRef || !scheduledNotifications || performanceData.length === 0) return;

        // Destroy existing chart instance if it exists
        if (canvasRef.chartInstance) {
            canvasRef.chartInstance.destroy();
        }

        // Filter notification times 
        const notificationTimes = scheduledNotifications.flatMap((range) =>
            range.notifications.map((notification) => {
                const notificationDate = new Date(notification.notificationTime);
                return {
                    date: notificationDate.toISOString().split('T')[0],
                    hour: notificationDate.getHours(),
                };
            })
        );

        // Get unique hours and sort them
        const uniqueHours = [...new Set(notificationTimes.map((nt) => nt.hour))].sort((a, b) => a - b);
        const timeSlots = uniqueHours.map((hour) => `${hour}:00`);

        // Group performance scores by hour
        const groupedScores = uniqueHours.map((hour) => ({
            hour,
            scores: [],
        }));

        // Filter and group performance data
        performanceData.forEach((entry) => {
            const entryDate = new Date(entry.timestamp);
            const entryDateString = entryDate.toISOString().split('T')[0];
            const entryHour = entryDate.getHours();

            // Check if performance entry matches notification times
            const isValid = notificationTimes.some(
                (nt) => nt.date === entryDateString && nt.hour === entryHour
            );

            if (isValid) {
                const matchingGroup = groupedScores.find((group) => group.hour === entryHour);
                if (matchingGroup) {
                    matchingGroup.scores.push(entry.score);
                }
            }
        });

        // Calculate average scores for each time slot
        const averageScores = groupedScores.map((group) => {
            if (group.scores.length === 0) return 0;
            return group.scores.reduce((a, b) => a + b, 0) / group.scores.length;
        });

        // Color mapping function
        const getColorForValue = (value) => {
            if (value === null || value === 0) return '#808080';
            const roundedValue = Math.round(value * 100) / 100;
            if (roundedValue >= 1 && roundedValue < 3) return '#ADD8E6';
            if (roundedValue > 3 && roundedValue < 5) return '#0000FF';
            if (roundedValue > 5 && roundedValue < 7) return '#00008B';
            if (roundedValue > 7 && roundedValue <= 10) return '#008000';
            return '#808080';
        };

        // Create chart context
        const ctx = canvasRef.getContext('2d');

        // Create new Chart.js instance
        const newChartInstance = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: timeSlots,
                datasets: [{
                    label: 'Average Performance Score',
                    data: averageScores,
                    backgroundColor: averageScores.map((score) => getColorForValue(score)),
                }],
            },
            options: {
                responsive: true,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => {
                                return `Average Score: ${tooltipItem.raw.toFixed(2)}`;
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Time Slots',
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Average Score',
                        },
                        suggestedMin: 0,
                        suggestedMax: 10,
                    },
                },
            },
        });

        // Attach chart instance to canvas ref
        canvasRef.chartInstance = newChartInstance;
    };

    const generateWeeklyChart = () => {
        // Existing weekly chart generation logic from ApexCharts version
        const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
        const [startHour] = q20Data.startTime.split(':').map(Number);
        const duration = q20Data.duration;
        const timeSlots = Array.from({ length: duration }, (_, i) => `${startHour + i}:00`);

        const startDate = new Date(selectedPeriod.startDate);
        const endDate = new Date(selectedPeriod.endDate);

        const scoresMatrix = Array.from({ length: weekdays.length }, () =>
            Array(timeSlots.length).fill(null)
        );

        performanceData.forEach(entry => {
            const entryDate = new Date(entry.timestamp);
            const weekdayIndex = entryDate.getDay() - 1;
            const hour = entryDate.getHours();
            const timeSlotIndex = hour - startHour;

            if (weekdayIndex >= 0 && weekdayIndex < 5 &&
                timeSlotIndex >= 0 && timeSlotIndex < duration &&
                entryDate >= startDate && entryDate <= endDate) {

                if (scoresMatrix[weekdayIndex][timeSlotIndex] === null) {
                    scoresMatrix[weekdayIndex][timeSlotIndex] = [];
                }
                scoresMatrix[weekdayIndex][timeSlotIndex].push(entry.score);
            }
        });

        const series = timeSlots.map((timeSlot, timeSlotIndex) => ({
            name: timeSlot,
            data: weekdays.map((_, weekdayIndex) => {
                const scores = scoresMatrix[weekdayIndex][timeSlotIndex];
                return scores && scores.length
                    ? Math.floor(scores.reduce((a, b) => a + b, 0) / scores.length)
                    : null;
            })
        }));

        setApexChartSeries(series);
        setApexChartOptions({
            chart: { type: 'bar', stacked: true, toolbar: { show: true } },
            xaxis: {
                categories: weekdays,
                title: { text: 'Weekdays' }
            },
            yaxis: {
                title: { text: 'Scores' },
                labels: {
                    formatter: (value) => (value === null ? 'No Data' : value)
                }
            },
            tooltip: {
                y: {
                    formatter: (value) => {
                        if (value === null) return 'No Data';
                        if (value >= 1 && value <= 2) return `Score: ${value} (1-2)`;
                        if (value >= 3 && value <= 4) return `Score: ${value} (3-4)`;
                        if (value >= 5 && value <= 6) return `Score: ${value} (5-6)`;
                        if (value >= 7 && value <= 10) return `Score: ${value} (7-10)`;
                        return 'Score: No Data';
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: { position: 'top' },
                    distributed: false
                }
            },
            fill: { opacity: 1 },
            legend: { show: true }
        });
    };

    // Data fetching effect
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [notificationsResponse, performanceResponses, surveyData] = await Promise.all([
                    getScheduledNotificationForObservationPeriod(userId),
                    getPerformanceResponses(userId),
                    fetchQ20Data(userId)
                ]);

                const { dateRanges } = notificationsResponse;
                setScheduledNotifications(dateRanges);

                if (dateRanges.length > 0) {
                    setSelectedPeriod({
                        startDate: dateRanges[0].startDate,
                        endDate: dateRanges[0].endDate,
                        label: `Period 1: ${format(new Date(dateRanges[0].startDate), 'yyyy-MM-dd')}`
                    });
                }

                setPerformanceData(performanceResponses.data || []);
                setQ20Data(surveyData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userId]);

    const toggleChartView = () => {
        setChartView(prevView => prevView === 'hourly' ? 'weekly' : 'hourly');
    };


    // Chart generation effects
    useEffect(() => {
        if (chartView === 'weekly') {
            generateWeeklyChart();
        }
    }, [selectedPeriod, q20Data, performanceData, chartView]);

    // Render check
    if (!selectedPeriod || !q20Data.startTime || !q20Data.duration || !performanceData.length) {
        return <Typography>Loading chart...</Typography>;
    }

    return (
        <Box sx={{ padding: 4 }}>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <IconButton onClick={onReturn}>
                    <ArrowBack />
                </IconButton>
                <Typography variant="h6" sx={{ ml: 2, flexGrow: 1 }}>
                    Performance Question Progress
                </Typography>
                <Tooltip title="Switch Chart View">
                    <IconButton onClick={toggleChartView}>
                        <CompareArrows />
                    </IconButton>
                </Tooltip>
            </Box>

            <Paper
                elevation={3}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                    mb: 2
                }}
            >
                <Box display="flex" alignItems="center">
                    {chartView === 'hourly' ? <HourlyIcon sx={{ mr: 1 }} /> : <WeeklyIcon sx={{ mr: 1 }} />}
                    <Typography>
                        {chartView === 'hourly' ? 'Hourly Performance View' : 'Weekly Performance View'}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body2" color="textSecondary">
                        {chartView === 'hourly'
                            ? 'Shows average performance scores by hour'
                            : 'Shows performance scores across weekdays and time slots'}
                    </Typography>
                </Box>
            </Paper>



            <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel>Select Observation Period</InputLabel>
                <Select
                    value={selectedPeriod?.startDate || ''}
                    onChange={(event) => {
                        const selected = scheduledNotifications.find(
                            range => range.startDate === event.target.value
                        );
                        setSelectedPeriod({
                            startDate: selected.startDate,
                            endDate: selected.endDate,
                            label: `Period ${scheduledNotifications.indexOf(selected) + 1}: ${format(new Date(selected.startDate), 'yyyy-MM-dd')}`
                        });
                    }}
                >
                    {scheduledNotifications.map((range, index) => (
                        <MenuItem key={index} value={range.startDate}>
                            {`Period ${index + 1}: ${format(new Date(range.startDate), 'yyyy-MM-dd')} - ${format(new Date(range.endDate), 'yyyy-MM-dd')}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {chartView === 'hourly' ? (
                <Box>
                    <Box sx={{ mb: 4 }}>
                        <canvas
                            ref={(ref) => {
                                if (ref) {
                                    setChartRef(ref);
                                    generateHourlyChart(ref);
                                }
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mb: 2,
                            flexWrap: 'wrap'
                        }}
                    >
                        {[
                            { color: '#ADD8E6', range: '1-2', label: 'Very Low' },
                            { color: '#0000FF', range: '3-4', label: 'Low' },
                            { color: '#00008B', range: '5-6', label: 'Moderate' },
                            { color: '#008000', range: '7-10', label: 'High' },
                            { color: '#808080', range: 'N/A', label: 'No Data' }
                        ].map((item) => (
                            <Box
                                key={item.range}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mr: 2,
                                    mb: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: item.color,
                                        width: 20,
                                        height: 20,
                                        mr: 1
                                    }}
                                />
                                <Typography sx={{ fontSize: '12px' }}>
                                    {`${item.range}: ${item.label}`}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            ) : (
                // Conditional rendering for ApexCharts
                apexChartOptions && apexChartSeries.length > 0 ? (
                    <Box sx={{ mb: 4 }}>
                        <ApexCharts
                            options={apexChartOptions}
                            series={apexChartSeries}
                            type="bar"
                            height={400}
                        />
                    </Box>
                ) : (
                    <Typography>Loading chart...</Typography>
                )
            )}
        </Box>
    );
};

export default SurveyProgressBar;